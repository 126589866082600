<template>
  <div class="home" :style="{paddingBottom:'50px'}">
    <van-swipe style="height:12rem" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img
          src="@/assets/img/20190904140921.jpg"
          style="width:100%;height:auto;margin: 0 auto;"
          alt
          srcset
        >
      </van-swipe-item>
      <van-swipe-item>
        <img
          src="@/assets/img/20190904140921.jpg"
          style="width:100%;height:auto;margin: 0 auto;"
          alt
          srcset
        >
      </van-swipe-item>
      <van-swipe-item>
        <img
          src="@/assets/img/20190904140921.jpg"
          style="width:100%;height:auto;margin: 0 auto;"
          alt
          srcset
        >
      </van-swipe-item>
      <van-swipe-item>
        <img
          src="@/assets/img/20190904140921.jpg"
          style="width:100%;height:auto;margin: 0 auto;"
          alt
          srcset
        >
      </van-swipe-item>
    </van-swipe>
    <!-- {{ cubeWidth }}
    {{ cubeHeight }} -->
    <!-- <div>
      <div style="">一刀智投3.0</div>
    </div> -->
    <div style="padding:6px">
      <van-row style="padding-bottom:6px; padding-top:5px">
        <div :style="{}" @click="showYidao()">
          <img src="@/assets/img/yidaoh5.png" style="width:100%;height:auto" alt="" srcset="">
        </div>
      </van-row>

    </div>

    <van-row v-if="isWeiXin&&unlogin" type="flex" justify="center" style="margin-bottom:10px">
      <van-button style="" color="" @click="toLogin()">注册/登陆</van-button>

      <!-- <van-button style="margin-right:10px;" size="small">忘记密码</van-button> -->
    </van-row>
    <!-- <van-row>
      <van-col span="8" offset="9">
        <van-button @click="toLogin">登陆/注册</van-button>
      </van-col>
    </van-row>
    <van-button @click="toDemo">goto demo</van-button> -->
    <!--
    <van-row gutter="10">
      <van-col span="16">
        <div style="background-color:green;height:150px">
          abc
        </div>
      </van-col>
      <van-col span="8"><div style="background-color:pink;height:150px">...</div></van-col>
    </van-row> -->

  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import { Swipe, SwipeItem, Row, Col, Button } from 'vant'
import * as sysTools from '../../utils/sysTools'
Vue.use(Row).use(Col)
Vue.use(Swipe).use(SwipeItem)
Vue.use(Button)

export default {
	name: 'Home',
	data() {
		return {
			userinfo: '',
			unlogin: true
		}
	},
	computed: {
		cubeWidth: () => {
			return document.body.clientWidth
		},
		cubeHeight: () => {
			return document.body.clientHeight
		},
		devicePixelRatio: () => {
			return window.devicePixelRatio
		},

		theUA() {
			return sysTools.getUA()
		},
		isWeiXin() {
			if (this.theUA.indexOf('micromessenger') !== -1) {
				return true
			} else {
				return false
			}
		}
	},
	mounted() {

	},
	methods: {

		toLogin() {
			this.$router.push({
				path: '/regLogin/1'
			})
		},
		toDemo() {
			this.$router.push({
				path: '/demo'
			})
		},
		showYidao() {
			this.$router.push({
				path: '/yidao'
			})
		},
		unloginDialog() {

		}

	}
}
</script>
<style lang="less" scoped>
.tiles-group {
  margin-top: 50px;
  width: 100%;
  background-color: rgba(0, 0, 255, 0.233);
  float: left;
  // grid setting
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  grid-template-rows: repeat(auto-fit, 70px);
  grid-gap: 10px;
  .tile-item {

    display: block;
    box-shadow: inset 0 0 1px #ffffcc;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .tile-medium {
    width: 150px;
    height: 150px;
    grid-column: span 2;
    grid-row: span 2;
  }
  .tile-wide {
    width: 310px;
    height: 150px;
    grid-column: span 4;
    grid-row: span 2;
  }

}
</style>

import Vue from 'vue'

// By default we import all the components.
// Only reserve the components on demand and remove the rest.
// Style is always required.
import {
	/* eslint-disable no-unused-vars */
	Style,
	// basic
	Button,
	Loading,
	Tip,
	Toolbar,
	TabBar,
	TabPanels,
	// form
	Checkbox,
	CheckboxGroup,
	Checker,
	Radio,
	RadioGroup,
	Input,
	Textarea,
	Select,
	Switch,
	Rate,
	Validator,
	Upload,
	Form,
	// popup
	Popup,
	Toast,
	Picker,
	CascadePicker,
	DatePicker,
	TimePicker,
	SegmentPicker,
	Dialog,
	ActionSheet,
	Drawer,
	ImagePreview,
	// scroll
	Scroll,
	Slide,
	IndexList,
	Swipe,
	Sticky,
	ScrollNav,
	ScrollNavBar
} from 'cube-ui'

Vue.use(Button)
// Vue.use(Loading)
// Vue.use(Tip)
// Vue.use(Toolbar)
// Vue.use(TabBar)
// Vue.use(TabPanels)
// Vue.use(Checkbox)
// Vue.use(CheckboxGroup)
// Vue.use(Checker)
// Vue.use(Radio)
// Vue.use(RadioGroup)
Vue.use(Input)
// Vue.use(Textarea)
// Vue.use(Select)
// Vue.use(Switch)
// Vue.use(Rate)
// Vue.use(Validator)
// Vue.use(Upload)
// Vue.use(Form)
// Vue.use(Popup)
// Vue.use(Toast)
// Vue.use(Picker)
// Vue.use(CascadePicker)
// Vue.use(DatePicker)
// Vue.use(TimePicker)
// Vue.use(SegmentPicker)
// Vue.use(Dialog)
// Vue.use(ActionSheet)
// Vue.use(Drawer)
// Vue.use(ImagePreview)
// Vue.use(Scroll)
// Vue.use(Slide)
// Vue.use(IndexList)
// Vue.use(Swipe)
// Vue.use(Sticky)
// Vue.use(ScrollNav)
// Vue.use(ScrollNavBar)

<template>
  <div id="homev2">
    <img style="height:98vh;width:100vw" src="https://static.ainvestcn.com/h5/bg2.05129199.png" alt="">
    <div v-if="!isWeiXin" class="top">
      <img class="logo" src="https://static.ainvestcn.com/h5/topLogo%402x.fdcb6507.png" alt="">
      <!-- <button class="button">hello</button> -->
    </div>
    <div class="middle">
      <img v-if="unlogin" class="login" src="https://static.ainvestcn.com/h5/loginbtn1%402x.ae58c5e4.png" alt="登录" @click="login()">
      <img v-if="unlogin" class="reg" src="https://static.ainvestcn.com/h5/regbtn1%402x.0660e95d.png" alt="注册体验" @click="register()">

      <div v-if="!unlogin" class="card">

        <img style="width:33vh;margin:" src="https://static.ainvestcn.com/h5/qrcode_for_gh_2236ea8bc1c0_258.jpg" alt="" srcset="">
        <br>
        长按关注“爱玩特智能”
      </div>

    </div>
    <!--

     -->
    <div class="bottom">
      <!-- <van-tabbar route fixed active-color="#f2826a" inactive-color="#000">
        <van-tabbar-item to="/home" icon="https://static.ainvestcn.com/h5/icon/home-icon.png">首页</van-tabbar-item>
        <van-tabbar-item to="/strategylist" icon="https://static.ainvestcn.com/h5/icon/shop-icon.png">策略优选</van-tabbar-item>
        <van-tabbar-item to="/user" icon="https://static.ainvestcn.com/h5/icon/user-icon.png">个人中心</van-tabbar-item>
      </van-tabbar> -->
      <van-tabbar route fixed>
<!--        <van-tabbar-item to="/home">-->
<!--          <img-->
<!--            slot="icon"-->
<!--            slot-scope="props"-->
<!--            :src="props.active ? icons.home.active : icons.home.normal"-->
<!--          >-->
<!--          <span>首页</span>-->
<!--        </van-tabbar-item>-->
        <van-tabbar-item to="/strategylist">
          <img
            slot="icon"
            slot-scope="props"
            :src="props.active ? icons.mall.active : icons.mall.normal"
          >
          <span>
            策略商城
          </span>
        </van-tabbar-item>
<!--        <van-tabbar-item to="/user">-->
<!--          <img-->
<!--            slot="icon"-->
<!--            slot-scope="props"-->
<!--            :src="props.active ? icons.user.active : icons.user.normal"-->
<!--          >-->
<!--          <span>-->
<!--            个人中心-->
<!--          </span>-->

<!--        </van-tabbar-item>-->
      </van-tabbar>

    </div>
  </div>
</template>
<script>
// const Base64 = require('js-base64').Base64
import * as sysTools from '../../utils/sysTools'
import { Swipe, SwipeItem, Tabbar, TabbarItem, Step, Steps, Tabs, Tab } from 'vant'
import {
	islogin,
	logout
//  evtlogadd
} from '@/api/theApi'
export default {
	components: {
		[Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		[Tabbar.name]: Tabbar,
		[TabbarItem.name]: TabbarItem,
		[Steps.name]: Steps,
		[Step.name]: Step,
		[Tabs.name]: Tabs,
		[Tab.name]: Tab
	},
	data() {
		return {
			steps: 2,
			icons: {
				home: {
					name: '首页',
					active: 'https://static.ainvestcn.com/h5/icon/home-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/home-icon.png'
				},
				mall: {
					name: '策略商城',
					active: 'https://static.ainvestcn.com/h5/icon/shop-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/shop-icon.png'
				},
				user: {
					name: '用户中心',
					active: 'https://static.ainvestcn.com/h5/icon/user-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/user-icon.png'
				}
			},
			prizeList: [
				{ content: '用户 1301****827 累计收益 2000 元', src: 'id' },
				{ content: '用户 1301****827 累计收益 2000 元', src: 'id' }

			],
			unlogin: true,
			active: 0
		}
	},
	computed: {
		theUA() {
			return sysTools.getUA()
		},

		isWeiXin() {
			if (this.theUA.indexOf('micromessenger') !== -1) {
				return true
			} else {
				return false
			}
		}
	},
	created() {
		this.init()
	},
	methods: {
		async init() {
			// console.log('url', this.$route.query)
			// const qr = this.$route.query.qr
			// const agt = this.$route.query.agt
			// const ip = 'noip'
			// const page = 'h5home'
			// if (qr === '1') {
			//   evtlogadd('scanQr1', this.theUA, ip, page)
			// }
			// if (qr === '2') {
			//   evtlogadd('wexinh5', this.theUA, ip, page)
			// }
			// if (agt) {
			//   localStorage.setItem('agent', agt)
			// }
			const isloginRes = await islogin()
			const username = localStorage.getItem('username')
			// console.log(username)

			if (isloginRes.login_status && username) {
				// Toast.loading('已经登陆')
				// const b64u = Base64.encode(username)
				// console.log(username)
				this.unlogin = false
			} else {
				// localStorage.clear()
				this.unlogin = true
			}
			if (!isloginRes.login_status && username) {
				this.unlogin = true

				localStorage.clear()
			}
			if (isloginRes.login_status && !username) {
				this.unlogin = true

				// Toast.fail('登录过期\n请重新登陆')
				logout().then(
					() => {

					}
				)
			}
		},
		login() {
			this.$router.push({
				path: '/login'
			})
		},
		register() {
			this.$router.push({
				path: '/regLogin'
			})
		},
		usercenter() {
			this.$router.push({
				path: '/user'
			})
		}
	}
}
</script>

<style lang="less" scoped>
#homev2 {
  position: relative;
  height: 100vh;
  width: 100vw;
  // display: flex;
  // flex-direction: column;
}
.top {
  // bottom: 4rem;
  top: 0;
  // height:3.5rem;
  height: 7.5vh;
  // position: absolute;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.99);
  .logo{

    width:44vw;
    // height: 5vh;
    margin-left:28vw;
    margin-top:1.2vh;
  }
  .button{
    width:20vw;
    float: right;
    height: 5vh;
    background-color: aqua
  }
}
.middle {
  bottom: 7.5vh;
  top: 7.5vh;
  // height: 15rem;
  position: absolute;
  width: 100vw;
  .login{
    width:100vw;
    margin-top:55.5vh;
  }
  .reg{
    margin-top: -1.7vh;
    width:90.3vw;
    margin-left:4.8vw;
  }
  .card{
    position: relative;
    // background-color: rgba(58, 58, 58, 0.308);
    color:white;
    width:90vw;
    margin: 1px auto;
    margin-top: 35vh;
    border-radius: 2px;
    // height: 45vh;
    text-align: center;
    vertical-align:middle;
  }
}
.bottom {
  bottom: 0;
  // height:3.5rem;
  height: 7.5vh;
  position: absolute;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.11);

  .active{
    color: rgba(0,0,0,1)
  }

  .normal{
    color:rgba(102,102,102,1);
  }
}

.active-icon{
  width:47px;
  height: 43px;
  background:linear-gradient(180deg,rgba(255,51,51,1),rgba(255,117,117,1));
}

</style>

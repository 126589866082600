/**
 * 获取浏览器UA
 */
export function getUA() {
	const ua = navigator.userAgent.toLocaleLowerCase()
	return ua
}
/**
 * 是否为微信
 */
export function isWeixin() {
	return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}

/**
 * utf8to16
 * @param {*} str
 */
export function utf8to16(str) {
	var out, i, len, c
	out = ''
	len = str.length
	for (i = 0; i < len; i++) {
		c = str.charCodeAt(i)
		if ((c >= 0x0001) && (c <= 0x007F)) {
			out += str.charAt(i)
		} else if (c > 0x07FF) {
			out += String.fromCharCode(0xE0 | ((c >> 12) & 0x0F))
			out += String.fromCharCode(0x80 | ((c >> 6) & 0x3F))
			out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F))
		} else {
			out += String.fromCharCode(0xC0 | ((c >> 6) & 0x1F))
			out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F))
		}
	}
	return out
}

/**
 * utf16to8
 * @param {*} str
 */
export function utf16to8(str) {
	var out, i, len, c
	var char2, char3
	out = ''
	len = str.length
	i = 0
	while (i < len) {
		c = str.charCodeAt(i++)
		switch (c >> 4) {
		case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7: out += str.charAt(i - 1)
			break
		case 12: case 13:
			// 110x xxxx 10xx xxxx
			char2 = str.charCodeAt(i++)
			out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F))
			break
		case 14:
			// 1110 xxxx 10xx xxxx 10xx xxxx
			char2 = str.charCodeAt(i++)
			char3 = str.charCodeAt(i++)
			out += String.fromCharCode(((c & 0x0F) << 12) |
                  ((char2 & 0x3F) << 6) |
                  ((char3 & 0x3F) << 0))
			break
		}
	}
	return out
}

/**
 * base64加密,明文-->base64
 * @param {String} str
 */
export function b64encode(str) {
	const encode = encodeURI(str)
	const b64str = btoa(encode)
	return b64str
}
/**
 * base64解密,base64字符串-->明文
 * @param {String} b64str
 */
export function b64decode(b64str) {
	const decode = atob(b64str)
	const str = decodeURI(decode)
	return str
}
/**
 * 检验是否为11位（2+9）手机号
 * @return {boolen} true,false
 */
export function vfphone() {
	const pattern = /^1[3456789]\d{9}$/
	// const isused =
	if (pattern.test(this.phone)) {
		return true
	}
	return false
}

/**
 * 生成指定长度随机随机字符串
 * @param {number} len 长度
 */
export function randomStr(len) {
	len = len || 10
	const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
	const maxPos = chars.length
	let randomStr = ''
	for (let index = 0; index < len; index++) {
		randomStr += chars.charAt(Math.floor(Math.random() * maxPos))
	}
	return randomStr
}
/**
 * 从arr 中删除 delVal
 * @param {array} arr 被操作的arraylist
 * @param {obj} delVal 要删除的元素
 */
export function arrayRemoveItem(arr, delVal) {
	if (arr instanceof Array) {
		var index = arr.indexOf(delVal)
		if (index > -1) {
			arr.splice(index, 1)
		}
	}
}


import Vue from 'vue'
import Router from 'vue-router'
import Layout from './layout/index.vue'
import Home from './views/home'
import Homev2 from './views/home/homev2.vue'
import NotFound from './views/errView/NotFound.vue'

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'index',
			component: Layout,
			redirect: '/strategylist',
			children: [
				{
					path: '/homev1',
					name: 'home',
					meta: { title: '一刀智投3.0' },
					component: Home
				},
				{
					path: '/user/:openNow?',
					name: 'user',
					meta: {
						title: '用户中心'
					},
					component: () => import('./views/user/index.vue')
				}
			]
		},
		{
			path: '/strategy/detail/:id/:cid?/',
			name: 'strategyDetail',
			meta: {
				title: '策略详情'
			},
			component: () => import('./views/strategy/detail.vue')
		},
		{
			path: '/chat',
			name: 'chat',
			meta: {
				title: '联系客服'
			},
			component: () => import('./views/chat/v1qrcode.vue')
		},
		{
			path: '/register/:phone?',
			name: 'register',
			meta: {
				title: '注册'
			},
			component: () => import('./views/register/v2.vue')
		},
		{
			path: '/regLogin/:islogin?',
			name: 'regLogin',
			meta: {
				title: '注册/登录'
			},
			component: () => import('./views/regLogin/regLogin.vue')
		},
		{
			path: '/login',
			name: 'login',
			meta: {
				title: '登录'
			},
			component: () => import('./views/login/index.vue')
		},
		{
			path: '/yidao',
			name: 'yidao',
			meta: {
				title: '一刀智投 3.0'
			},
			component: () => import('./views/yidao/index.vue')
		},
		{
			path: '/home/:agent?',
			name: 'h5v2home',
			meta: {
				title: 'AINVEST'
			},
			component: Homev2
		},
		{
			path: '/strategylist',
			name: 'strategylist',
			meta: {
				title: '策略列表'
			},
			component: () => import('./views/strategy/listv2.vue')
		},
		{
			path: '/strategylist3',
			name: 'strategylist3',
			meta: {
				title: '策略列表'
			},
			component: () => import('./views/strategy/listv3.vue')
		},
		{
			path: '/pay',
			name: 'pay',
			meta: {
				title: '支付'
			},
			component: () => import('./views/pay/v12.vue')
		},
		{
			path: '/pay/result',
			name: 'payResult',
			meta: {
				title: '支付结果'
			},
			component: () => import('./views/pay/result.vue')
		},
		{
			path: '/proxy/apply',
			nanme: 'proxy-apply',
			meta: {
				title: '申请成为合伙人'
			},
			component: () => import('./views/proxy/info.vue')
		},
		{
			path: '/userservice',
			name: 'userservice',
			meta: {
				title: '用户服务协议'
			},
			component: () => import('./views/userservice/userservice.vue')
		},
		{
			path: '/privacypollcy',
			name: 'privacypollcy',
			meta: {
				title: '用户服务协议'
			},
			component: () => import('./views/userservice/privacyPollcy.vue')
		},
		{ path: '*', component: NotFound }
	]
})


import request from '@/utils/http'

export function login(username, password) {
	const data = { username: username, password: password }
	return request({
		url: '/api/ainvest_login/',
		method: 'post',
		data
	})
}
export function logout() {
	return request({
		url: '/api/ainvest_logout/',
		method: 'post'
	})
}
export function bind_state(username) {
	const data = {username: username};
	return request({
		url: '/v3/bind_state',
		method: 'post',
		data
	})
}
/**
 * 检测当前会话是否处于登陆检查
 */
export function islogin() {
	return request({
		url: '/api/office_web/judge_login/',
		method: 'get'
	})
}
/**
 * 订阅选择策略 需要改名subscribe
 * @param {String|Number} strategyid
 * @param {String|Number} classid
 */
export function choseStrategy(strategyid, classid) {
	const data = { new_strategy_id: strategyid, new_class_id: classid }
	return request({
		url: '/api/office_web/update_personal_strategy/',
		method: 'post',
		data
	})
}

/**
 * 切换自动交易启动状态
 * @param {String|Number} status_code
 */
export function toggleYidao(status_code) {
	const data = { 'status_code': status_code }
	return request({
		url: '/api/office_web/change_use/',
		method: 'post',
		data
	})
}

/**
 * 获取用户信息
 * @param {id:userid,token:token,username:username} data
 */
export function getUserInfofromNewServer(username) {
	const data = {
		username: username,
		nativeCode: 'imAinvestcnabc'
		// phoneNumber: phoneNumber
	}
	return request({
		url: '/v3/getuserinfobn',
		method: 'post',
		data
	})
}
export function getUserInfofromOldServer(data) {
	return request({
		url: '/api/office_web/get_personal_info/',
		method: 'get'
	})
}


export function customerReturnNew(username) {
	return request({
		url: `/v3/customer_return_new?u=${username}`,
		method: 'get'
	})
}

/**
 * 获取策略列表(排行榜)
 */
export function fetchStrategylist() {
	return request({
		url: '/api/office_web/get_list/',
		method: 'get'
	})
}

/**
 * 获取策略优选列表(排行榜)
 */
export function fetchGoodStrategylist(data) {
	return request({
		url: '/v3/recommend_strategies',
		method: 'post',
		data: {}
	})
}

/**
 * 获取全部策略列表(排行榜)
 */
export function fetchAllStrategylist(data) {
	return request({
		url: '/v3/strategies',
		method: 'post',
		data
	})
}

/**
 * 获取 指定 策略
 *
 */
export function fetchStrategy(sid, day, cid) {
	// https://www.ainvestcn.com/api/office_web/strategy_chart/?strategy_number=3&show_days=1&category_group=1
	const url = '/api/office_web/strategy_chart/?strategy_number=' + sid + '&show_days=' + day + '&category_group=' + cid
	return request({
		url: url,
		method: 'get'
	})
}

/**
 * 检测手机号是否占用
 * @param {String} phoneNumber
 */
export function usedphonenumber(phoneNumber) {
	const url = '/v3/usedphonenumber'
	const data = {
		nativeCode: 'imAinvestcnabc',
		phoneNumber: phoneNumber
	}
	return request({
		url: url,
		method: 'post',
		data
	})
}
/**
 * sms code
 * @param {*} phoneNumber
 */
export function getSmsCode(phoneNumber) {
	const url = '/v3/smscode'
	const data = {
		nativeKey: 'imAinvestcnabc',
		phoneNumber: phoneNumber
	}
	return request({
		url: url,
		method: 'post',
		data
	})
}
export function loginsmscode(phoneNumber) {
	const url = '/v3/loginsmscode'
	const data = {
		nativekey: 'imAinvestcnabc',
		phonenumber: phoneNumber
	}
	return request({
		url: url,
		method: 'post',
		data
	})
}
/**
 * 检查验证码
 * @param {String} phoneNumber 手机号
 * @param {String} smscode 验证码
 */
export function ckSmsCode(phoneNumber, smscode) {
	const url = '/v3/verifysmscode'
	const data = {
		nativeCode: 'imAinvestcnabc',
		phoneNumber: phoneNumber,
		smsCode: smscode
	}
	return request({
		url: url,
		method: 'post',
		data
	})
}
/**
 * 用户注册接口
 * @param {
 *  nativeCode:String,
 *  username:String,
 *  phoneNumber:String
 * } data
 */
export function register(data) {
	const url = '/v3/register'
	return request({
		url: url,
		method: 'post',
		data
	})
}

/**
 * 快速注册接口
 * @param {
 *  nativeCode:String,
 *  phone:String,
 *  code:String,
 *  ua:String,
 *  ip:String
 * } data
 */
export function fastReg(data) {
	const url = '/v3/register'
	return request({
		url: url,
		method: 'post',
		data
	})
}

/**
 * 申请星火
 * @param {
 *  nativeCode:String,
 *  username:String,
 *  phoneNumber:String
 * } data
 */
export function applyYidao(data) {
	const url = '/v3/applyyidao'
	return request({
		url: url,
		method: 'post',
		data
	})
}

/**
 * 获取券商列表
 */
export function fetchSecurities() {
	const url = '/v3/securities'
	return request({
		url: url,
		method: 'get'
	})
}

/**
 * 获取历史持仓
 */
export function fetchHistory(sid, cid) {
	const url = '/api/office_web/new_transaction_record/?strategy_number=' + sid + '&category_group=' + cid
	return request({
		url: url,
		method: 'get'
	})
}
/**
 * 获取历史持仓
 */
export function fetchHistoryWithDate(sid, cid, date) {
	const url = '/api/office_web/new_transaction_record/?strategy_number=' + sid + '&category_group=' + cid + '&date=' + date
	return request({
		url: url,
		method: 'get'
	})
}
/**
 * 获取调仓记录
 */
export function fetchWarehouseHistory(sid, cid) {
	// https://www.ainvestcn.com/api/office_web/adjust_record/?strategy_number=3&category_group=1
	const url = '/api/office_web/adjust_record/?strategy_number=' + sid + '&category_group=' + cid
	return request({
		url: url,
		method: 'get'
	})
}
/**
 *
 * @param {*} sid
 * @param {*} cid
 * @param {*} date
 */
export function fetchWarehouseHistoryWithDate(sid, cid, date) {
	// https://www.ainvestcn.com/api/office_web/adjust_record/?strategy_number=3&category_group=1
	const url = '/api/office_web/adjust_record/?strategy_number=' + sid + '&category_group=' + cid + '&date=' + date
	return request({
		url: url,
		method: 'get'
	})
}

/**
 * 申请模拟账号
 */
export function fetchdemoAccount() {
	return request({
		url: '/api/office_web/get_demo_account/',
		method: 'get'
	})
}
/**
 *
 */
// 历史持仓
export function fetchTradeRecord() {
	return request({
		url: '/api/office_web/personal_transaction_record/',
		method: 'get'
	})
}
// 历史交易
export function fetchAdjustRecord() {
	return request({
		url: '/api/office_web/new_personal_adjust_record/',
		method: 'get'
	})
}
// 历史持仓日期查询
export function fetchTradeRecordWithDate(date) {
	return request({
		url: '/api/office_web/personal_transaction_record/?date=' + date,
		method: 'get'
	})
}
// 历史交易日期查询
export function fetchAdjustRecordWithDate(date) {
	return request({
		url: '/api/office_web/new_personal_adjust_record/?date=' + date,
		method: 'get'
	})
}

export function evtlogadd(evnt, ua, ip, page) {
	const data = {
		phone: '',
		event: evnt,
		ua: ua,
		ipv4: ip,
		page: page
	}
	return request({
		url: '/v3/addEvtlog',
		method: 'post',
		data
	})
}

export function evtlogadd2(evnt, phone, ua, ip) {

}
/**
 * 提交订单检查
 * @param {*} ordernum
 * @param {*} username
 * @param {*} agent
 */
export function addcheck(ordernum, username, agent) {
	const url = '/v3/addcheck'
	const data = {
		nativeCode: 'imAinvestcnabc',
		number: ordernum,
		username: username,
		agent: agent
	}
	return request({
		url: url,
		method: 'post',
		data: data
	})
}
export function isPayTime(username) {
	const url = '/v3/paid'
	const data = {
		nativeCode: 'imAinvestcnabc',
		username: username
	}
	return request({
		url: url,
		method: 'post',
		data: data
	})
}
export function regtime() {
	const url = 'api/office_web/registration_time'
	return request({
		url: url,
		method: 'get'
	})
}

export function isvipApi(username) {
	const data = {
		nativeCode: 'imAinvestcnabc',
		username: username
	}
	return request({
		url: '/v3/isvip',
		method: 'post',
		data
	})
}
// reg/autoApplymn
export function autoApplymn(sessionid) {
	const data = {
		nativeCode: 'imAinvestcnabc'
	}
	return request({
		url: '/v3/autoApplyMon1',
		method: 'post',
		data
	})
}
export function loginv2(phone, code) {
	const data = {
		phone_number: phone,
		verify_code: code
	}
	return request({
		url: '/api/ainvest_login_new/',
		method: 'post',
		data
	})
}
export function paylog(phone, invitationCode) {
	const data = {
		nativeCode: 'nativeCode',
		phone: phone,
		invitationCode: invitationCode
	}
	return request({
		url: '/v3/paylog',
		method: 'post',
		data
	})
}

export function agentApply(phoneNumber, fullname, inviteByCode) {
	const data = {
		fullname: fullname,
		phoneNumber: phoneNumber,
		inviteByCode: inviteByCode
	}
	return request({
		url: '/v3/agent/apply',
		method: 'post',
		data
	})
}

/**
 * 切换锁定开关,设置切换比例， 又写一起了，哈哈哈， 留坑
 * @param {string} phone 手机号
 */
export function toggleLockMoney(phone, lockpercent) {
	const data = {
		phone: phone,
		lockpercent: lockpercent,
		nativeCode: 'imAinvestcnabc'
	}
	return request({
		url: '/v3/togglelockmoney',
		method: 'post',
		data: data
	})
}

/**
 * 历史记录
 * @param {string} username 手机号
 */
export function history_adjusts(username) {
	const data = {
		username: username
	}
	return request({
		url: '/v3/history_adjusts',
		method: 'post',
		data: data
	})
}

/**
 * 支付
 * @param {string} username
 * @param {Boolean} isMoble
 * @param {string} alipay
 * @param {string} couponCode
 */
export function pay_vip(data) {
	return request({
		url: '/v3/pay_vip?platform=' + data.platform + '&isMobile=' + data.isMobile + '&username=' + data.username + '&couponCode=' + data.couponCode + '&openId='+data.openId,
		method: 'get'
	})
}

export function pay_vip2(data) {
	return request({
		url: '/v3/pay_vip2?platform=' + data.platform + '&isMobile=' + data.isMobile + '&username=' + data.username + '&couponCode=' + data.couponCode + '&openId='+data.openId,
		method: 'get'
	})
}

/**
 * 拿到微信openid
 * @param {string} username
 * @param {Boolean} isMoble
 * @param {string} alipay
 * @param {string} couponCode
 */
export function getWeOpenId(data) {
	return request({
		url: '/v3/wx_openid?code=' + data.code,
		method: 'get'
	})
}


/**
 * 拿到微信openid
 * @param {string} username
 * @param {Boolean} isMoble
 * @param {string} alipay
 * @param {string} couponCode
 */
export function bindInviteCode(data) {
	return request({
		url: '/v3/bind_invite_code',
		method: 'post',
		data: data
	})
}

<template>
  <div id="layout">
    <!-- 如果是微信浏览不显示的顶部 -->
    <Header v-if="!isWeiXin" title="一刀智投3.0" :unlogin="unlogin" />
    <!-- 如果是微信浏览不显示的顶部 -->
    <!-- 中间 main area -->
    <div class="middle" :style="!isWeiXin?{paddingTop:'50px'}:{}">
      <router-view />
    </div>
    <!-- 中间 main area -->
    <!-- 底部 tabBar -->
    <Bottom class="bottom" />
    <!-- 底部 tabBar -->
  </div>
</template>
<script>

import Vue from 'vue'
import { NavBar, Button, Icon, Row, Col, Toast } from 'vant'
import { islogin, logout } from '@/api/theApi'
import Bottom from './Bottom'
import Header from './Header'
Vue.use(Button)
Vue.use(NavBar)
Vue.use(Icon)
Vue.use(Row).use(Col)

import * as sysTools from '../utils/sysTools'
export default {
	components: {
		Header,
		Bottom
	},
	data() {
		return {
			active: 0,
			icon: {
				normal: '//img.yzcdn.cn/icon-normal.png',
				active: '//img.yzcdn.cn/icon-active.png'
			},
			unlogin: true
		}
	},
	computed: {

		theUA() {
			return sysTools.getUA()
		},
		isWeiXin() {
			if (this.theUA.indexOf('micromessenger') !== -1) {
				return true
			} else {
				return false
			}
		}
	},
	created() {
		this.init()
	},
	methods: {
		async init() {
			const isloginRes = await islogin()
			const username = localStorage.getItem('username')
			if (isloginRes.login_status && username) {
				// Toast.loading('已经登陆')
				// const b64u = Base64.encode(username)
				this.unlogin = false
			} else {
				// localStorage.clear()
				this.unlogin = true
			}
			if (!isloginRes.login_status && username) {
				this.unlogin = true

				localStorage.clear()
			}
			if (isloginRes.login_status && !username) {
				this.unlogin = true

				Toast.fail('登录过期\n请重新登陆')
				logout().then(
					() => {

					}
				)
			}
		}
	}
}
</script>
<style lang="less" scoped>
#layout {
  position: absolute;
  height: 100%;
  width: 100%;
  // display: flex;
  // flex-direction: column;
}
.middle {
  // bottom: 4rem;
  // top: 4rem;
  // height: 15rem;
  // position: absolute;
  width: 100%;
  padding-bottom: 60px;
}
.bottom {
  // bottom: 0;
  // // height:4rem;
  // position: absolute;
  width: 100%;
  // background-color: yellow;
}
</style>

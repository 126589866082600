<template>
  <div id="app">

    <router-view />
  </div>
</template>

<script>
export default {
	updated() {
	},
	methods: {
	}
}
</script>
<script>
export default {};
</script>
<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f3f6;
}

#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  .ice-burg {
    position: fixed;
    width: 10vw;
    height: 20vw;
    right: 1vw;
    top: 40.2vh;
    background-color: rgba(255, 255, 255, 0.618);
    z-index: 5;
    border-top-left-radius: 2px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    img{
      width: 10vw;

    }
  }
  .ice-burg2 {
    position: fixed;
    display: flex;
    width: 10vw;
    height: 20vw;
    right: 1vw;
    top: 46vh;
    background-color: rgba(255, 255, 255, 0.618);
    z-index: 5;
    border-top-left-radius: 2px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 2px;
    flex-direction: column;
    align-items: center;
    align-content: center;
    img{
      width: 10vw;
    }
  }
}
</style>

<template>
  <div class="top">
    <van-nav-bar :title="title" fixed>
      <!-- menu btn start -->
      <!-- <van-button slot="left" size="small">
        <van-icon name="wap-nav" />
      </van-button> -->
      <!-- menu btn end -->
      <!-- right tool menu start -->
      <van-button v-if="unlogin" slot="right" size="small" @click="toLogin()">
        注册/登陆
      </van-button>
      <van-button v-else slot="right" size="small" @click="toLogout()">退出</van-button>

      <!-- right tool menu start -->
    </van-nav-bar>
  </div>
</template>
<script>
import { logout } from '@/api/theApi'
export default {
	props: {
		title: {
			type: String,
			default: 'Ainvest'
		},
		unlogin: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		toLogin() {
			this.$router.push({
				path: '/regLogin/1'
			})
		},
		toLogout() {
			localStorage.clear()
			logout()
			window.location.href = '/'
		}
	}
}
</script>

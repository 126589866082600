<template>
  <van-tabbar route fixed>
<!--    <van-tabbar-item to="/home">-->
<!--      <img-->
<!--        slot="icon"-->
<!--        slot-scope="props"-->
<!--        :src="props.active ? icons.home.active : icons.home.normal"-->
<!--      >-->
<!--      <span>首页</span>-->
<!--    </van-tabbar-item>-->
    <van-tabbar-item to="/strategylist">
      <img
        slot="icon"
        slot-scope="props"
        :src="props.active ? icons.mall.active : icons.mall.normal"
      >
      <span>
        策略商城
      </span>
    </van-tabbar-item>
<!--    <van-tabbar-item to="/user">-->
<!--      <img-->
<!--        slot="icon"-->
<!--        slot-scope="props"-->
<!--        :src="props.active ? icons.user.active : icons.user.normal"-->
<!--      >-->
<!--      <span>-->
<!--        个人中心-->
<!--      </span>-->
<!--    </van-tabbar-item>-->

  </van-tabbar>
</template>
<script>
import Vue from 'vue'
import { Tabbar, TabbarItem } from 'vant'
Vue.use(Tabbar).use(TabbarItem)
export default {
	name: 'Bottom',
	props: {
		// tabs: {
		//   type: Object,
		//   default: () => {
		//     return [
		//       {
		//         path: '/home',
		//         name: '首页',
		//         icon: 'home-o',
		//         iconpath: ''
		//       },
		//       {
		//         path: '/startegylist',
		//         name: '策略优选',
		//         icon: 'search',
		//         iconpath: ''
		//       },
		//       {
		//         path: '/usercenter',
		//         name: '用户中心',
		//         icon: 'user-o',
		//         iconpath: ''
		//       }
		//     ]
		//   } // 默认数据
		// }
	},
	data() {
		return {
			icons: {
				home: {
					name: '首页',
					active: 'https://static.ainvestcn.com/h5/icon/home-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/home-icon.png'
				},
				mall: {
					name: '策略商城',
					active: 'https://static.ainvestcn.com/h5/icon/shop-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/shop-icon.png'
				},
				user: {
					name: '用户中心',
					active: 'https://static.ainvestcn.com/h5/icon/user-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/user-icon.png'
				}
			},
			unlogin: true,
			active: 0
		}
	}
}
</script>
